<template>
  <div class="login-page bg-primary">

    <section class="p-relative z-2">
      <div class="row">
        <div class="lg:col-6 md-down:col-12 xl:col-5 bg-white content-center" style="min-height: 100vh">

          <div class="text-center pt-10">
            <img :src="require('../../../assets/logo-word.svg')" class="w-contain d-inline-block mb-2" alt="" style="max-width: 250px;">
            <small class="text-muted d-block strong">Recovery Password</small>
          </div>

          <div class="w-100 my-auto mx-auto py-5" style="max-width: 600px;">

            <div class="text-center pb-5">
              <p class="mb-0">Enter the new password then tour password will be change!</p>
              <p class="mb-0">Don`t forget again :)</p>
            </div>

            <div class="form-group">

              <label class="form-label" :class="{'text-danger': !validPassword}">Password</label>

              <div class="input-wrap">
                <input class="form-input" type="password" placeholder="••••••••" v-model="password"/>
              </div>
            </div>

            <div class="form-group">

              <label class="form-label" :class="{'text-danger': !validConfirm}">Confirm Password</label>

              <div class="input-wrap">
                <input class="form-input" type="password" placeholder="••••••••" v-model="confirm" @keyup="onKeyUp"/>
              </div>
            </div>

            <div class="d-flex">
              <span class="btn px-4 bg-primary ml-auto w-100" :class="{loading, disabled: !password || !validPassword || !confirm || !validConfirm}" @click="submit">Submit</span>
            </div>
          </div>

          <div class="text-center pb-4">
            <small class="text-muted">ARROUND</small>
          </div>
        </div>
        <div class="col p-relative md-down:d-none">
          <bg-particles class="opacity-80" :count="60"/>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import BgParticles from '../../components/bg-particles'

  export default {
    name: 'Login',
    data(){
      return {
        email: this.$route.query.email,
        recovery_code: this.$route.query.recovery_code,
        password: '',
        confirm: '',
        remember: true,
        loading: false
      }
    },
    computed: {
      validPassword() {
        let valid = true;

        this.$check(this.password)
          .then((a) => a && a.length < 3, () => {valid = false})

        return valid
      },
      validConfirm() {
        let valid = true;

        this.$check(this.confirm)
          .then((a) => a && a.length < 3, () => {valid = false})
          .then((a) => a && a !== this.password, () => {valid = false})

        return valid
      }
    },
    // beforeMount() {
    //   this.$router.replace({
    //     ...this.$router.currentRoute,
    //     query: {}
    //   })
    // },
    methods: {

      onKeyUp(event) {
        if(event.key === 'Enter') this.submit();
      },

      submit: function() {

        if(this.confirm && this.validConfirm){

          this.loading = true;

          let data = {
            recovery_code: this.recovery_code,
            email: this.email,
            new_password: this.password
          };

          this.$api.v2.put('/email/passRecovery', data)
              .then(() => { this.loading = false })
              .catch(() => { this.loading = false });
        }
      }
    },
    components: {
      BgParticles
    }
  }
</script>
