var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-page bg-primary" }, [
    _c("section", { staticClass: "p-relative z-2" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass:
              "lg:col-6 md-down:col-12 xl:col-5 bg-white content-center",
            staticStyle: { "min-height": "100vh" }
          },
          [
            _c("div", { staticClass: "text-center pt-10" }, [
              _c("img", {
                staticClass: "w-contain d-inline-block mb-2",
                staticStyle: { "max-width": "250px" },
                attrs: {
                  src: require("../../../assets/logo-word.svg"),
                  alt: ""
                }
              }),
              _vm._v(" "),
              _c("small", { staticClass: "text-muted d-block strong" }, [
                _vm._v("Recovery Password")
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "w-100 my-auto mx-auto py-5",
                staticStyle: { "max-width": "600px" }
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "form-label",
                      class: { "text-danger": !_vm.validPassword }
                    },
                    [_vm._v("Password")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrap" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password"
                        }
                      ],
                      staticClass: "form-input",
                      attrs: { type: "password", placeholder: "••••••••" },
                      domProps: { value: _vm.password },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.password = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "form-label",
                      class: { "text-danger": !_vm.validConfirm }
                    },
                    [_vm._v("Confirm Password")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrap" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.confirm,
                          expression: "confirm"
                        }
                      ],
                      staticClass: "form-input",
                      attrs: { type: "password", placeholder: "••••••••" },
                      domProps: { value: _vm.confirm },
                      on: {
                        keyup: _vm.onKeyUp,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.confirm = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex" }, [
                  _c(
                    "span",
                    {
                      staticClass: "btn px-4 bg-primary ml-auto w-100",
                      class: {
                        loading: _vm.loading,
                        disabled:
                          !_vm.password ||
                          !_vm.validPassword ||
                          !_vm.confirm ||
                          !_vm.validConfirm
                      },
                      on: { click: _vm.submit }
                    },
                    [_vm._v("Submit")]
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _vm._m(1)
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col p-relative md-down:d-none" },
          [
            _c("bg-particles", {
              staticClass: "opacity-80",
              attrs: { count: 60 }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center pb-5" }, [
      _c("p", { staticClass: "mb-0" }, [
        _vm._v("Enter the new password then tour password will be change!")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mb-0" }, [_vm._v("Don`t forget again :)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center pb-4" }, [
      _c("small", { staticClass: "text-muted" }, [_vm._v("ARROUND")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }